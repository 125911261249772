import React from 'react';
import { Link } from 'react-router-dom';
import HeaderCartImage from '../../../assets/icons/header-icon-mini-cart.svg';
import { useCartContext } from '../../../context/CartContext';
import HeaderCartPopup from '../HeaderCartPopup/HeaderCartPopup';
import './HeaderMiniCart.scss';

const HeaderMiniCart = () => {
  const { cart, loading } = useCartContext();
  return (
    <div className="header__menu-item header__menu-item_cart">
      <Link to="/checkout/cart">
        <img className="header__cart-pic" src={HeaderCartImage} alt="cart" />
        {cart && cart.total_quantity > 0 && (
          <span className="header__cart-counter">{cart.items.length}</span>
        )}
      </Link>
      <HeaderCartPopup cart={cart} loading={loading} />
    </div>
  );
};

export default React.memo(HeaderMiniCart);
