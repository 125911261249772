import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import {T} from '@deity-io/falcon-i18n';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import Button from '../../UI/Button/Button';
import TextButton from '../../UI/TextButton/TextButton';
import HeaderCartItem from '../HeadeCartItem/HeaderCartItem';
import Prices from '../../BaseCurrencyPrices/Prices';
import './HeaderCartPopup.scss';

const HeaderCartPopup = ({ cart, loading }) => {
  if (!cart || loading) {
    return (
      <div className="cart-popup">
        <Loader height="xxxl" />
      </div>
    );
  }

  // NOTE: Quick fix to remove the null item issue - no known cause yet
  cart.items = cart.items.filter((x) => x)

    const showModal = cart.items.some((item) => {
        return item.product.is_on_order_only !== 0 && item.product.days_to_delivery !== 0
    })

  if (!cart.items.length) {
    return (
      <div className="cart-popup">
        <span className="message empty">
          <T id="emptyCart.isEmpty" />
        </span>
      </div>
    );
  }

  return (
    <div className="cart-popup">
        {showModal &&
            <div className="modalText">
                <T id="cart.orderInTheStore"/>
            </div>
        }
      <div className=" cart-popup__header">
        <T id="headerCart.totalQuantity" /> {cart.items.length}
      </div>
      <div className="cart-popup__items">
        {cart.items.map(item => (
          <HeaderCartItem item={item} key={item.id} />
        ))}
      </div>
      <div className="cart-popup__total">
        <div className="cart-popup__total-price">
          <div className="cart-popup__total-text">
            <T id="headerCart.total" />
          </div>
          <div className="cart-popup__total-amount">
            <Prices amount={cart.prices.grand_total.value} isMinicart breakRow />
          </div>
        </div>
          <div className="cart-popup__checkout">
              <Link to="/checkout">
                  <div className="btn btn_hover-submit">
                      <T id="headerCart.checkout"/>
                  </div>
              </Link>


              <Link to="/checkout/cart">
                  <TextButton className="cart-popup__cart-link">
                      <T id="headerCart.viewCart"/>
                  </TextButton>
              </Link>
          </div>
      </div>
    </div>
  );
};

export default memo(HeaderCartPopup);
